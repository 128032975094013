import { Modal } from '../_UI'
import { Icon, Button } from 'components'
import { Text } from '@solides/management-util-ui'

interface Button {
  label?: string
  loading?: boolean
  action: () => void
}

interface Props {
  title: string | React.ReactNode
  description?: string
  icon?: string
  iconVariant?: 'round' | 'outlined'
  open: boolean
  cancelButton?: Button
  confirmButton: Button
}

export const ModalAction = ({
  title,
  description = '',
  icon = '',
  iconVariant = 'outlined',
  cancelButton = null,
  confirmButton,
  open
}: Props) => (
  <Modal
    open={open}
    className="flex flex-col gap-y-4 items-center"
    size="360px"
  >
    {icon && (
      <Icon
        face={icon}
        size="xl"
        className="text-primary"
        variant={iconVariant}
      />
    )}

    <Text variant="h5" className="text-gray-900 text-center">
      {title}
    </Text>

    {description && (
      <Text variant="body1" className="text-gray-700 text-center">
        {description}
      </Text>
    )}

    <div className="mt-4 w-full flex gap-x-4 [&>button]:shadow-none [&>button]:w-full [&>button]:justify-center">
      {cancelButton && (
        <Button
          onClick={cancelButton.action}
          className="bg-transparent text-primary !border !border-primary-700/50"
        >
          {cancelButton?.label || 'Cancelar'}
        </Button>
      )}
      <Button onClick={confirmButton.action} loading={confirmButton?.loading}>
        {confirmButton?.label || 'Confirmar'}
      </Button>
    </div>
  </Modal>
)
