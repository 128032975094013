import Link from 'next/link'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { Spinner } from 'components/Spinner'
import { useStore } from 'lib/store'

interface Props extends ComponentProps<'button'> {
  href?: string
  loading?: boolean | string
  colorCompany?: boolean
}

export const Button = ({
  className = '',
  loading = false,
  colorCompany = false,
  children,
  ...props
}: Props) => {
  const company = useStore(store => store.company)

  const style = twMerge(
    's-button flex justify-center px-10 py-1.5 font-bold relative text-[13px] sm:text-[14px] bg-primary rounded-lg hover:bg-opacity-85 disabled:text-state-disabled/[0.8] disabled:bg-state-disabled-background/[0.24] disabled:cursor-not-allowed',
    loading
      ? 'opacity-85 pointer-events-none text-transparent select-none !bg-gray-600/80'
      : 'text-white transition duration-300 ease-in-out',
    colorCompany && company.color.background,
    className
  )

  if (props?.href) {
    return (
      <Link href={props.href} className={style}>
        {children}
      </Link>
    )
  }

  return (
    <button className={style} {...props}>
      {!!loading && (
        <span className="absolute inset-0 flex justify-center items-center gap-3 text-white">
          <Spinner />
          {typeof loading === 'string' ? loading : ''}
        </span>
      )}
      {children}
    </button>
  )
}
