import { User } from 'types/user'
import { create, StoreApi, UseBoundStore } from 'zustand'

import { cookies } from 'lib/cookies'
import type { Company } from 'types/company'
import { KEY_USER_STORAGE } from 'utils/constants'
import { handleSignIn, handleSignOut } from 'services/user'

export interface Filter {
  title: string
  locations: string
  page: number
}

interface StoreProps {
  user: User
  isLoadingUser: boolean
  company: Company
  signIn: () => void
  signOut: () => void
  updateStoreCompany: (updateData: Company) => void
  filter: Filter
  updateStoreFilter: (updateData: Partial<Filter>) => string
  updateStoreUser: (updateData: Partial<User>) => void
}

export type Store = UseBoundStore<StoreApi<StoreProps>>

export const useStore = create<StoreProps>((set, get) => ({
  user: null,
  isLoadingUser: false,
  company: {
    color: {
      hex: '#fff',
      text: 'text-[#fff]',
      background: 'bg-primary'
    }
  } as never,
  filter: {
    title: '',
    locations: '',
    page: 1
  },
  updateStoreFilter: updateData => {
    const filter: Filter = { ...get().filter, ...updateData }
    set(state => ({ ...state, filter }))

    const url = new URLSearchParams()
    filter.page && url.set('page', filter.page.toString())
    filter.locations && url.set('locations', filter.locations)
    filter.title && url.set('title', filter.title)

    return url.toString()
  },
  updateStoreCompany: updateData => {
    const currentCompany = get().company
    const company = { ...currentCompany, ...updateData }
    set(state => ({ ...state, company }))
  },
  signOut: () => {
    handleSignOut()
    set(state => ({ ...state, user: null }))
  },
  signIn: async () => {
    const idToken = cookies.get('idToken')

    if (idToken) {
      set(state => ({ ...state, isLoadingUser: true }))
      const keyStorage = KEY_USER_STORAGE + idToken.slice(-5)
      const localUser = sessionStorage.getItem(keyStorage)

      const companyId = get().company.referenceId

      const user = localUser
        ? JSON.parse(localUser)
        : await handleSignIn(companyId)
      set(state => ({ ...state, isLoadingUser: false, user }))
    }
  },
  updateStoreUser: updateData => {
    const idToken = cookies.get('idToken')

    if (idToken) {
      const currentUser = get().user
      const user = { ...currentUser, ...updateData }

      const keyStorage = KEY_USER_STORAGE + idToken.slice(-5)
      sessionStorage.setItem(keyStorage, JSON.stringify(user))

      set(state => ({ ...state, user }))
    }
  }
}))
