import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const Box = ({
  children,
  className = '',
  ...props
}: ComponentProps<'div'>) => (
  <div
    // eslint-disable-next-line prettier/prettier
    className={twMerge('bg-background-paper shadow-md rounded-lg w-full p-6', className)}
    {...props}
  >
    {children}
  </div>
)
