import { useEffect } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'

import { Layout } from 'components'
import { useStore } from 'lib/store'
import { queryClient } from 'lib/react-query'
import { ToastProvider } from 'hooks/useToast'
import { initGoogleTagManager } from 'utils/script-GA'
import { initHotjar } from 'utils/script-hotjar'
import { getCompany, setCacheCompany } from 'services/company'

import 'styles/globals.css'

export default function MyApp({ Component, pageProps }) {
  const company = pageProps?.company
  const { updateStoreCompany, signIn } = useStore()

  useEffect(() => {
    initGoogleTagManager()
    initHotjar()

    if (company) {
      updateStoreCompany(company)
      signIn()

      if (company.type !== 'cache') {
        setCacheCompany(company)
      }
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ToastProvider>
    </QueryClientProvider>
  )
}

MyApp.getInitialProps = async props => {
  const host = props?.ctx?.req?.headers?.host
  const slug = host ? host.split('.')[0] : ''
  // const slug = 'mca'

  let companyCookie = props?.ctx?.req?.cookies?.jobs_company

  if (companyCookie) {
    companyCookie = JSON.parse(companyCookie)

    if (slug === companyCookie.slug) {
      return { pageProps: { company: { ...companyCookie, type: 'cache' } } }
    }
  }

  const company = await getCompany(slug)
  return { pageProps: { company: { ...company, type: 'complete' } } }
}
