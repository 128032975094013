import { twMerge } from 'tailwind-merge'
import { useStore } from 'lib/store'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const LayoutBanner = () => {
  const router = useRouter()
  const company = useStore(store => store.company)
  const isRecommendationPage = router.asPath.includes('indicacao')

  const classBanner = useMemo(() => {
    if (isRecommendationPage) {
      return "bg-[url('/banner_recommendation.jpg')]"
    } else if (company.banner_mobile) {
      return `bg-[url('${company.banner_mobile}')] sm:bg-[url('${company.banner}')]`
    } else if (company.banner) {
      return `bg-[url('${company.banner}')]`
    }
    return company.color.background
  }, [company])

  if (router.asPath.includes('/vaga/')) {
    return <></>
  }

  return (
    <div
      className={twMerge(
        'w-full h-56 md:h-[300px] absolute top-0 left-0 right-0 bg-blend-overlay bg-top bg-no-repeat bg-cover',
        classBanner
      )}
    />
  )
}
